import { GetApp } from '@material-ui/icons'
import { Box, Button, Grid, TextField, Tooltip } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Locale from 'date-fns/locale/sq'
import moment from 'moment'
import { exportLeavesCSV } from './../utils/exportLeavesCSV'
import { exportLeavesDocs } from '../utils/exportLeavesDocs'

const TableHeader = ({ thisObject }) => {
  let timeoutId = null
  const isMobile = useMediaQuery('(max-width:767px)')

  const DatePickerComponent = isMobile ? MobileDatePicker : DesktopDatePicker

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    clearTimeout(timeoutId) // Clear any existing timeout
    timeoutId = setTimeout(() => {
      thisObject?.setState({ searchQuery: inputValue })
      thisObject?.loadDataTableData()
    }, 500) // Adjust delay as needed
  }

  const isApprovedChecked = thisObject?.state?.filterList?.status?.length === 1 && thisObject?.state?.filterList?.status[0] === 'Approved'

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={Locale}>
      <Grid container gap={3} sx={{ fieldset: { border: '1px solid' } }}>
        <Grid item>
          {!thisObject?.state?.selectedUserID && thisObject?.state?.activeTab == '1' && (
            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
              <TextField label='Kërko përdoruesin' size='small' onChange={handleInputChange} />
            </Box>
          )}
        </Grid>
        <Grid container item width='fit-content' gap={1}>
          <Grid item>
            <DatePickerComponent
              label='Prej:'
              openTo='year'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={thisObject?.state?.fromDateValue}
              onChange={(e) => {
                thisObject?.setState({ fromDateValue: moment(e).format('YYYY-MM-DD') }, () => {
                  thisObject?.loadDataTableData()
                })
              }}
              renderInput={(params) => <TextField size='small' {...params} sx={{ width: '150px' }} />}
            />
          </Grid>
          <Grid item>
            <DatePickerComponent
              label='Deri:'
              openTo='year'
              inputFormat='dd/MM/yyyy'
              views={['year', 'month', 'day']}
              value={thisObject?.state?.toDateValue}
              onChange={(e) => {
                thisObject?.setState({ toDateValue: moment(e).format('YYYY-MM-DD') }, () => {
                  thisObject?.loadDataTableData()
                })
              }}
              renderInput={(params) => <TextField size='small' {...params} sx={{ width: '150px' }} />}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={() => exportLeavesCSV(thisObject)} size='small' variant='contained' startIcon={<GetApp />}>
            Shkarko
          </Button>
        </Grid>
        <Grid item>
          <Tooltip title={isApprovedChecked ? 'Shkarko te gjitha dokumentet e gjetura' : 'Filtro permes statusit Approved'}>
            {/* div intentionally not deleted, kept to display tooltip when button is disabled */}
            <div>
              <Button
                onClick={() => exportLeavesDocs(thisObject)}
                disabled={!isApprovedChecked}
                size='small'
                variant='outlined'
                startIcon={<GetApp />}
              >
                Shkarko Aprovimet
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    </LocalizationProvider>
  )
}

export default TableHeader
