import axios from 'axios'
import Swal from 'sweetalert2'
import { DOCUMENTS_API } from '../../../utils/consts'

export const exportLeavesDocs = async (thisObject) => {
  const showSwalError = (message = 'Gabim gjatë shkarkimit të dokumentit') => {
    Swal.fire({
      title: message,
      text: message,
      type: 'warning'
    })
  }
  try {
    Swal.fire({
      title: 'Ju lutem prisni...',
      text: 'Duke gjeneruar dokumentin',
      didOpen: () => {
        Swal.showLoading()
      }
    })

    const params = {
      ...thisObject?.state?.filters,
      fromDateValue: thisObject?.state?.fromDateValue,
      toDateValue: thisObject?.state?.toDateValue,
      search: thisObject?.state?.searchQuery || undefined,
      userID: thisObject?.state?.selectedUserID || undefined
    }
    const response = await axios.get(`${DOCUMENTS_API}/exportMultipleLeaves`, {
      responseType: 'blob',
      params
    })

    if (response?.data) {
      if (response.headers['content-type'] == 'application/zip') {
        saveAs(response.data, 'Leaves.zip')
        Swal.close()
        Swal.fire({
          title: 'Te dhenat u shkarkuan me sukses.',
          text: 'Dokumenti eshte gjeneruar me sukses!',
          type: 'success'
        })
      }
    } else {
      showSwalError()
    }
  } catch (error) {
    console.log('error', error)
    showSwalError()
  }
}
